.place-card {
}

.place-card-time {
    color: #777;
    font-size: 0.75rem;
    font-weight: bold;
}

.place-card-title {
    font-size: 16px;
    max-width: 250px;
}

.place-card-description {
    font-size: 16px;
}