.underlined-tab {
    position: relative;
}

.underlined-tab::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

.underlined-tab::after {
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
}

.underlined-tab:hover::after,
.underlined-tab:focus::after,
.tab-enabled::after {
    transform: scale(1);
}

.tab:hover::after,
.tab:focus::after,
.tab-enabled::after {
    opacity: 1;
    transform: translate3d(0, 0.2rem, 0);
}