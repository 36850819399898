.blinking-link {
    display: inline-block;
    padding: 2px;
    position: relative;
    transition: all .2s ease-out;
}

.blinking-link::after {
    content: " "!important;
    width: 100%;
    height: 1px;
    position: absolute!important;
    right: 0;
    bottom: 0;
    transition: all .2s ease-out;
    background-color: rgb(var(--main-text));
}

.blinking-link::before {
    content: " ";
    width: 4px;
    height: 4px;
    background: rgb(var(--light-main-text));
    position: absolute;
    right: -4px;
    bottom: 0;
    opacity: 0;
}

.blinking-link:hover {
    transition: all .1s ease-out;
    padding: 2px 8px;
}

.blinking-link:hover::after {
    width: 0;
    transition: all .1s ease-out;
}

.blinking-link:hover::before {
    opacity: 1;
    animation: b-link .4s infinite .1s;
    transition: all .1s ease-out;
}

@keyframes b-link {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}