.article-card {
    width: auto;
    margin: 0 1rem;
    height: 18rem;
    margin-bottom: 4rem;
}

.article-card .face {
    width: 25rem;
    height: 18rem;
    transition: 0.5s;
}

.article-card .face1 {
    display: flex;
    position: relative;

    z-index: 10001;
    transform: translateY(8rem);
}

.article-card:hover .face1 {
    transform: translateY(0);
}

.article-card .face1 .contenu {
    height: 100%;
    width:100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-top: 1rem;
    transition: 0.5s;
}

.article-card .face1 .contenu .art-title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.contenu h3 {
    align-self: center;
}

.article_pic {
    display: flex;

    justify-content: center;
    margin: auto;
    padding: 0 0.5rem;

    width: 90%;
    max-width: 100%;
    height: 80%;
}

.article_pic img {
    border-radius: 2rem;
}

.article-card:hover .face1 .contenu {
    opacity: 1;
    z-index: 10003;
}

.article-card:hover .face1 {
    z-index: 10003;
    opacity: 1;
}

.article-card:hover .face2 {
    z-index: 10002;
    opacity: 1;
}

.article-card .face1 .contenu .art-projet {
    max-width: 100%;
    object-fit: cover;
}

.article-card .face2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.8);
    transform: translateY(-10rem);
    z-index: 9999;
}

.article-card:hover .face2 {
    transform: translateY(0);
}

.article-card .face2 .contenu {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.article-card .face2 .contenu p {
    flex-grow: 1;

    margin: 0;
    padding: 0;

    /*color: var(--main-text-color);*/
}

@media (max-width: 768px) {
    .article-card {
        max-width: 15rem!important;
        height: 9rem!important;
    }

    .article-card:hover .face2 {
        transform: translateY(0)!important;
    }

    .face1 {
        width: 15rem!important;
        height: 12rem!important;
        transform: translateY(3rem);
    }

    .face2 {
        width: 15rem!important;
        height: 12rem!important;
        transform: translateY(-4rem)!important;
    }
}