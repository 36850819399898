.timeline-container {
    display: flex;
    position: relative;
    margin: 0.5rem;
    padding: 0 5rem;
    z-index: 10;
}

.timeline-container-vertical::after {
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
    z-index: -1;
    color: var(--dark-background);
}

.timeline-container-horizontal::after {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    height: 4px;
    width: 100%;
    color: var(--dark-background);
}

.timeline-container-vertical {
    flex-direction: column;
    margin: 40px 0;
}

.timeline-container-horizontal {
    flex-direction: row;
    margin: 0 40px;
    min-height: 40rem;
}

.timeline-item {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.timeline-item-horizontal {
    padding-top: 30px;
    height: 50%;
}

.timeline-item-vertical {
    margin: 0 10px;
    width: 50%;
    padding-left: 50px;
}

.timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
}

.timeline-item-horizontal:nth-child(even) {
    margin: 55px 0;
}

.timeline-item-vertical:nth-child(even) {
    margin-left: 0;
}

.timeline-item-vertical:nth-child(odd) {
    margin-right: -30px;
}

.timeline-container:nth-child(odd) .timeline-item-horizontal {
    padding-bottom: 30px;
    margin: 0;
    padding-top: 0;
}

.timeline-container:nth-child(odd) .timeline-item-vertical {
    padding-left: 0;
    padding-right: 30px;
}

.timeline-item-content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
}

.timeline-item-content-vertical {
    position: relative;
    width: 400px;
    max-width: 70%;
    text-align: right;
}

.timeline-item-content-horizontal {
    position: relative;
    width: 400px;
    max-height: 70%;
    text-align: center;
}

.timeline-item-content-horizontal::after {
    left: calc(50%);
}

.timeline-item-content-vertical::after {
    right: -7.5px;
}

.timeline-item-content::after {
    content: ' ';
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content-horizontal::after {
    top: -7.5px;
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(even) .timeline-item-content-horizontal::after {
    bottom: -7.5px;
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content-vertical {
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(even) .timeline-item-content-vertical {
    text-align: right;
    align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content-vertical::after {
    right: auto;
    top: calc(50% - 7.5px);
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item:nth-child(even) .timeline-item-content-vertical::after {
    top: calc(50% - 7.5px);
    text-align: right;
    align-items: flex-end;
}

.timeline-item-content .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}

.timeline-item-content .circle {
    border: 3px solid;
    border-radius: 50%;
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 100!important;
    opacity: 100%!important;
}

.timeline-item:nth-child(odd) .timeline-item-content-vertical .circle {
    top: calc(50% - 10px);
    left: -40px;
}

.timeline-item:nth-child(even) .timeline-item-content-vertical .circle {
    top: calc(50% - 10px);
    right: -40px;
}

@media (min-width: 768px) {
    .timeline-item:nth-child(even) .timeline-item-content-vertical .place-card-title {
        margin-left: auto;
    }
}


.timeline-item:nth-child(odd) .timeline-item-content-horizontal .circle {
    top: -40px;
    left: 49%;
}

.timeline-item:nth-child(even) .timeline-item-content-horizontal .circle {
    bottom: -40px;
    left: 49%;
}

@media only screen and (max-width: 1023px) {
    .timeline-item-content {
        max-width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    }

    .timeline-item-content .tag {
        width: calc(100% - 10px);
        text-align: center;
    }

    .timeline-item-content time {
        /* margin-top: 20px; */
        text-align: left;
    }

    .timeline-item-content a {
        text-decoration: underline;

        text-align: left;
    }

    .timeline-item-content a::after {
        display: none;
    }

    .circle {
        display: none;
    }

    .timeline-item-content .place-card-title {
        text-align: left;
    }

    .timeline-item-content .place-card-description {
        text-align: left;
    }

    .timeline-item-content::after {
        display: none;
    }
}