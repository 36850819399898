@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [data-theme="dark"] {
    --main-text: 249, 250, 250;
    --accent: 167, 211, 244;
    --light-main-text: 98, 98, 98;
    --dark-background: 42, 42, 46;
    --main-background: 56, 56, 61;
    --alert: 255, 193, 7;
    --light-button: 15, 255, 255;
    --light-button-text: 0, 0, 0;
  }

  [data-theme="light"] {
    --main-text: 56, 56, 61;
    --accent: 1, 104, 179;
    --light-main-text: 98, 98, 98;
    --dark-background: 213, 213, 213;
    --main-background: 255, 255, 255;
    --alert: 255, 0, 0;
    --light-button: 0, 0, 0;
    --light-button-text: 255, 255, 255;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar: red red solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
