.text-displayer > ul {
    list-style-type: disc;
    list-style-position: inside;
}

.text-displayer > ol {
    list-style-type: decimal;
    list-style-position: inside;
}

.text-displayer > ul ul,
.text-displayer > ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
}

.text-displayer > ol ol,
.text-displayer > ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
}

.text-displayer > ol > li {
    list-style-position: inside;
    margin-left: 2rem;
}

.text-displayer > ul > li {
    list-style-position: inside;
    margin-left: 2rem;
}

.ql-size-huge {
    font-size: 2.5rem;
}

.ql-size-large {
    font-size: 2rem;
}

.ql-size-normal {
    font-size: 1.5rem;
}

.ql-size-small {
    font-size: 0.75rem;
}

.ql-align-right {
    text-align: right;
}

.ql-align-center {
    text-align: center;
}

.ql-align-center img {
    margin: 0 auto;
}

.ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
}

.ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
}

.ql-syntax {
    background-color: rgb(var(--dark-background))!important;
    padding: 0.5rem;
    border-left: solid 2px rgb(var(--accent));
    overflow-x: auto;
}

.text-displayer blockquote {
    background-color: rgba(var(--dark-background));
    border-left: solid 2px rgb(var(--accent));
    padding: 0.5rem;
    margin-left: 0;
}

.text-displayer a {
    display: inline-block;
    padding: 2px;
    position: relative;
    transition: all .2s ease-out;
    z-index: 10;
}

.text-displayer a::after {
    content: " "!important;
    width: 100%;
    height: 1px;
    position: absolute!important;
    right: 0;
    bottom: 0;
    transition: all .2s ease-out;
    background-color: rgb(var(--main-text));
    z-index: -1;
}

.text-displayer a:hover {
    color: rgb(var(--main-background));
    transition: all .1s ease-out;
}

.text-displayer a:hover::after {
    height: 100%;
    transition: all .1s ease-out;
}