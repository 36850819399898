.quill-editor {
    overflow-x: auto;
    border: 0;
}

.ql-toolbar {
    position: absolute;
    z-index: 100000!important;
    border: 0 transparent!important;
}

.ql-container {
    padding-top: 2.85rem;
    border: 0 transparent!important;
}

.ql-align-justify {
    text-align: justify;
}

.ql-toolbar .ql-stroke {
    fill: none;
    stroke: rgb(var(--main-text))!important;
}

.ql-toolbar .ql-fill {
    fill: rgb(var(--main-text))!important;
    stroke: none;
}

.ql-picker-item {
    color: rgb(var(--dark-background));
}

.ql-picker-item > svg > .ql-stroke {
    fill: none;
    stroke: rgb(var(--dark-background))!important;
}

.ql-toolbar .ql-picker {
    color: rgb(var(--main-text));
}

.ql-editor.ql-blank::before {
    color: rgb(var(--main-text));
}