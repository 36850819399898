/*
  ANIMATIONS
*/
.reveal-base {
    transition: 0.5s cubic-bezier(.5, 0, 0, 1)!important;
}

.reveal-left {
    opacity: 0!important;
    transform: translateX(50px)!important;
}

.reveal-right {
    opacity: 0!important;
    transform: translateX(-50px)!important;
}

.reveal-top {
    opacity: 0!important;
    transform: translateY(-50px)!important;
}

.reveal-bottom {
    opacity: 0!important;
    transform: translateX(50px)!important;
}