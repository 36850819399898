.deployed {
    width: 25rem;
    transition: all 1s;
}

.wrapped {
    width: 5rem;
    transition: all 0.3s;
}

.data-visible {
    opacity: 1;
    animation: appear-from-left;
    animation-duration: 1.5s;
}

.data-invisible {
    display: none!important;
    opacity: 0;
    transition: opacity 0.15s;
}

@keyframes appear-from-left {
    0%, 35% {
        transform: translateX(-5rem);
        opacity: 0;
    }

    100% {
        transform: translateX(0rem);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .deployed {
        width: 100vh!important;
    }

    .wrapped {
        width: 3rem;
        transition: all 0.3s;
    }
}