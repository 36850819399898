
html[data-theme="dark"] .hljs {
    color: #abb2bf;
    background: #282c34;
}

html[data-theme="dark"] .hljs-comment,
html[data-theme="dark"] .hljs-quote {
    color: #5c6370;
    font-style: italic;
}

html[data-theme="dark"] .hljs-doctag,
html[data-theme="dark"] .hljs-keyword,
html[data-theme="dark"] .hljs-formula {
    color: #c678dd;
}

html[data-theme="dark"] .hljs-section,
html[data-theme="dark"] .hljs-name,
html[data-theme="dark"] .hljs-selector-tag,
html[data-theme="dark"] .hljs-deletion,
html[data-theme="dark"] .hljs-subst {
    color: #e06c75;
}

html[data-theme="dark"] .hljs-literal {
    color: #56b6c2;
}

html[data-theme="dark"] .hljs-string,
html[data-theme="dark"] .hljs-regexp,
html[data-theme="dark"] .hljs-addition,
html[data-theme="dark"] .hljs-attribute,
html[data-theme="dark"] .hljs-meta .hljs-string {
    color: #98c379;
}

html[data-theme="dark"] .hljs-attr,
html[data-theme="dark"] .hljs-variable,
html[data-theme="dark"] .hljs-template-variable,
html[data-theme="dark"] .hljs-type,
html[data-theme="dark"] .hljs-selector-class,
html[data-theme="dark"] .hljs-selector-attr,
html[data-theme="dark"] .hljs-selector-pseudo,
html[data-theme="dark"] .hljs-number {
    color: #d19a66;
}

html[data-theme="dark"] .hljs-symbol,
html[data-theme="dark"] .hljs-bullet,
html[data-theme="dark"] .hljs-link,
html[data-theme="dark"] .hljs-meta,
html[data-theme="dark"] .hljs-selector-id,
html[data-theme="dark"] .hljs-title {
    color: #61aeee;
}

html[data-theme="dark"] .hljs-built_in,
html[data-theme="dark"] .hljs-title.class_,
html[data-theme="dark"] .hljs-class .hljs-title {
    color: #e6c07b;
}

html[data-theme="dark"] .hljs-emphasis {
    font-style: italic;
}

html[data-theme="dark"] .hljs-strong {
    font-weight: bold;
}

html[data-theme="dark"] .hljs-link {
    text-decoration: underline;
}

html[data-theme="light"] .hljs {
    color: #383a42;
    background: #fafafa;
}

html[data-theme="light"] .hljs-comment,
html[data-theme="light"] .hljs-quote {
    color: #a0a1a7;
    font-style: italic;
}

html[data-theme="light"] .hljs-doctag,
html[data-theme="light"] .hljs-keyword,
html[data-theme="light"] .hljs-formula {
    color: #a626a4;
}

html[data-theme="light"] .hljs-section,
html[data-theme="light"] .hljs-name,
html[data-theme="light"] .hljs-selector-tag,
html[data-theme="light"] .hljs-deletion,
html[data-theme="light"] .hljs-subst {
    color: #e45649;
}

html[data-theme="light"] .hljs-literal {
    color: #0184bb;
}

html[data-theme="light"] .hljs-string,
html[data-theme="light"] .hljs-regexp,
html[data-theme="light"] .hljs-addition,
html[data-theme="light"] .hljs-attribute,
html[data-theme="light"] .hljs-meta .hljs-string {
    color: #50a14f;
}

html[data-theme="light"] .hljs-attr,
html[data-theme="light"] .hljs-variable,
html[data-theme="light"] .hljs-template-variable,
html[data-theme="light"] .hljs-type,
html[data-theme="light"] .hljs-selector-class,
html[data-theme="light"] .hljs-selector-attr,
html[data-theme="light"] .hljs-selector-pseudo,
html[data-theme="light"] .hljs-number {
    color: #986801;
}

html[data-theme="light"] .hljs-symbol,
html[data-theme="light"] .hljs-bullet,
html[data-theme="light"] .hljs-link,
html[data-theme="light"] .hljs-meta,
html[data-theme="light"] .hljs-selector-id,
html[data-theme="light"] .hljs-title {
    color: #4078f2;
}

html[data-theme="light"] .hljs-built_in,
html[data-theme="light"] .hljs-title.class_,
html[data-theme="light"] .hljs-class .hljs-title {
    color: #c18401;
}

html[data-theme="light"] .hljs-emphasis {
    font-style: italic;
}

html[data-theme="light"] .hljs-strong {
    font-weight: bold;
}

html[data-theme="light"] .hljs-link {
    text-decoration: underline;
}