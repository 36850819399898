.changing-text:after {
    content: "|";

    animation: infinite blip-typewriter 1s;
}

@keyframes blip-typewriter {
    0%, 49% {
        opacity: 0;
    }

    50%, 99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}