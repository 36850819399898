.loading-circle {
    display: inline-block;
    width: 172px;
    height: 172px;
    align-self: center;
    margin: auto 0;
}

.loading-circle:after {
    content: " ";
    display: block;
    width: 128px;
    height: 128px;

    margin: 16px;
    border-radius: 50%;

    border: 6px solid;
    border-color: rgba(var(--main-text), 1) transparent rgba(var(--main-text), 1) transparent;
    animation: loading-circle-spinning 1s linear infinite;
}

@keyframes loading-circle-spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}